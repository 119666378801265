import * as actionTypes from '../actions/actionTypes';

const initialState ={
    titoloPagina: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PAGE_TITLE:
            return {
                ...state,
                titoloPagina: action.titoloPagina
            };
        default:
            return state;
    }

};

export default reducer;