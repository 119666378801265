import * as actionTypes from '../actions/actionTypes';

import { updateObject } from '../../shared/utility';

const initialState = {
    token: null,
    userId: null,
    error: null,
    loading: false,
    authRedirectPath: ''
};

const authStart = (state, action) => {
    return updateObject(state, {error: null, loading: true});
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        error: null, 
        loading: false,
        token: action.token,
        userId: action.userId
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error, 
        loading: false,
        token: null,
        userId: null
    });
};

const authLogout = (state, action) => {
    return updateObject(state, {
        error: null,
        token: null,
        loading: false,
        userId: null
    })
};

const setAuthRedirectPath = (state, action) => {
    return updateObject(state, {
        authRedirectPath: action.authRedirectPath
    })
}

const addAuthToken = (state, action) => {
    return updateObject(state, {
        addAuthToken: action.addAuthToken
    })
}

const authRedir = (state, action)  => {
    return updateObject(state, {
        error: null,
        token: null,
        loading: false,
        userId: null
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return authStart(state, action);
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action);
        case actionTypes.AUTH_FAIL:
            return authFail(state, action);
        case actionTypes.AUTH_LOGOUT:
            return authRedir(state, action);
        case actionTypes.AUTH_REDIR:
            return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH:
            return setAuthRedirectPath(state, action);
        default:
            return state;
    }
}

export default reducer;