import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';

import './App.scss';
import layoutReducer from './store/reducers/layout';
import authReducer from './store/reducers/auth';

import thunk from 'redux-thunk';


const rootReducer = combineReducers({
  layout: layoutReducer,
  auth: authReducer
});

const store = createStore(rootReducer, applyMiddleware(thunk));

const loading = () => <div className="animated fadeIn pt-3 text-center">Caricamento in corso...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <HashRouter>
            <React.Suspense fallback={loading()}>
              <Switch>
                <Route exact path="/" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route 
                  path="/" 
                  name="Home" 
                  render={
                    props => 
                      <DefaultLayout 
                        {...props} 
                        superadmin={localStorage.getItem('user') !== null ? 
                                      JSON.parse(localStorage.getItem('user')).superadmin
                                    : 0} 
                        titolo='Calendario2'
                      />} 
                />
              </Switch>
            </React.Suspense>
        </HashRouter>
      </Provider>
    );
  }
}

export default App;
